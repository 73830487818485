


.customeBox{
	background-color: greenyellow;
	/* heightを固定してしまうと、ローディングスピナーが表示されても固定されるため、要素がはみ出て消える。％指定は意味ない */
	/* height: 30vh; */
}
.customeBox > .overlay{
	background: rgba(0, 0, 0, 0.5);
	
	/* 100pxでちょうどローディングスピナーが収まるぐらい。 */
	height: 100px;

	/* centering spinner */
	display: flex;
  align-items: center;
  justify-content: center;
}

/* Collapseの開閉で表示するアイコンを切り替える
　　空白は、下の要素の子孫要素を指定 */

[aria-expanded="true"] i#addCollapse:before {
	/* 開いてるとき。上向き矢印 */
  content: "\f106";
}
[data-toggle="collapse"].collapsed i#addCollapse:before{
	/* 閉じてるとき。下向き矢印 */
	content: "\f107";
}
/* [data-toggle="collapse"] i#addCollapse:before{
	content: "\f106";
} */

/* ======<ログイン画面用CSS：開始>================================================================== */

.loginForm{
  /* position:relative を指定しないとTopが反映されない */
  /* relative:100％になる　absolute:小さい大きさになる */
  position: absolute;
	width: calc(100%);
	
	/* 52pxは恐らくNavbarの高さ */
  height: calc(100vh - 52px);
	/* min-height: calc(100vh - 52px); */
	/* top: 52px; */

  /* 中身を中央寄せにするための4行 */
  display: flex;
  align-items: center;
  justify-content: center; 
	background-color: #d0d0d0;	
}
/* MyTOTPなどを変数参照にしたら消す */
.login-form{
  position: absolute;
  width: calc(100%);
  height: calc(100vh - 52px);
  display: flex;
  align-items: center;
  justify-content: center; 
  background-color: #d0d0d0;
}

/* ======<ログイン画面用CSS：終了>================================================================== */

/* ======<ローディングスピナー用CSS：開始>================================================================== */

/* 
 * ローディングスピナーを表示するために、黒い画面をコンテンツの上に重ねる
 * Sidebarのshow/hiddenによって、適用するCSSを変える 
 * 親要素のクラス名は常に表示されているため取得可能→javascriptで書き換え
*/
.showSidebar > .overlay{
	/* top leftの分だけ横幅と高さを調整。書かないと#mainのマージンに影響される */

	/* top:50pxは、ヘッダーの分 */
	/* top: 50px; */
	/* ヘッダーの下に隠す */
	top: 0px;

	/* left: 25vw; */
	left: 180px;
	/* width: calc(75%); */
	width: calc(100% - 180px);
	/* height: 100% - top は不要 */
  height: calc(100%);
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}


.hiddenSidebar > .overlay{
	/* top leftの分だけ横幅と高さを調整。書かないと#mainのマージンに影響される */

	/* ヘッダーの分を空ける場合 */
	/* top: 50px; */
	/* ヘッダーの下に隠す */
	top: 0px;

	/* サイドバーを隠す場合 */
	/* left: 0vw;
	width: calc(100%); */
	
	/* ★【サイドバー】サイドバーを細くする場合 */
	left: 40px;
	width: calc(100% - 40px);

	
	/* height: 100% - top は不要 */
  height: calc(100%);
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* ローディングスピナーの色を指定 */
.my-white{
  color: white;
}

/* サインイン時は、ローディングに左マージンを入れない */
.hiddenSidebarSignIn > .overlay{
	/* top leftの分だけ横幅と高さを調整。書かないと#mainのマージンに影響される */

	/* サインイン時は基本モーダルが出ないが一応 */
	/* top: 50px; */
	top: 0px;

	/* サイドバーを隠す場合 */
	left: 0vw;
	width: calc(100%);
	
	/* height: 100% - top は不要 */
  height: calc(100%);
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* ローディングスピナーの色を指定 */
.my-white{
  color: white;
}

/* ======<ログイン画面用CSS：終了>================================================================== */


/* メインコンテンツの背景色を指定 */
.mybgcolor-grey{
  background-color: #d0d0d0;
}

.btn{
  /* ボタン内の文字列が改行されるようになり、レスポンシブではみ出さなくなる */
  white-space: normal;
}

/* ======<テーブル用CSS：開始>================================================================== */

table{
  /* テーブルをレスポンシブでもはみ出させなくするCSS */
  width: 100%;
  table-layout: fixed;
  word-break: break-all;
  word-wrap: break-word;
}

/* デプロイファイルの見出し列の幅 */
.custom-td-extra-width{
	width: 150px;
}

/* 契約情報参照の見出し列の幅 */
.custom-td-width{
	width: 130px;
}
/* ユーザー管理のNo列の幅 */
.custom-td-width-number{
	width: 50px;
}
/* ユーザー管理の編集ボタン列の幅 */
.custom-td-width-edit{
	width: 110px;
}
.custom-td-width-pass{
	width: 180px;
}
.custom-td-width-add{
	width: 55px;
}

/* これをカードボディに設定すると、カードの中身がスクロール出来るようになる */
.custom-scroll{
	overflow: auto;
}
.custom-scroll-x{
	overflow-x: auto;
}
.custom-scroll-y{
	overflow-y: auto;
}
/* ################# EB環境一覧参照テーブルのカラム幅 */
/* ※カラム幅を指定する際、同じクラスで幅を指定しようとするとおかしくなる？→すべて変える */
.custom-td-width-eb-env-name{
	width: 100px;
}
.custom-td-width-eb-env-name-long{
	width: 160px;
	/* min-widthは、ブロック要素で画面幅によって他の要素の幅を可変にするための設定 */
	min-width: 160px;
}
.custom-td-width-eb-env-num{
	width: 80px;
}
.custom-td-width-eb-env-health{
	width: 100px;
}
.custom-td-width-eb-app-health{
	width: 110px;
}
.custom-td-width-eb-env-status{
	width: 80px;
}
.custom-td-width-eb-app-ver{
	width: 140px;
}
.custom-td-width-eb-day{
	width: 100px;
	min-width: 100px;
}
.custom-td-width-eb-day-long{
	width: 160px;
	min-width: 160px;
}
.custom-td-width-eb-rds{
	width: 120px;
}
.custom-td-width-eb-access{
	width: 100px;
}
.custom-td-width-eb-ver-manage-long{
	width: 110px;
	min-width: 110px;
}

.custom-td-width-eb-ver-manage{
	width: 100px;
}

.custom-td-width-eb-ver-manage-small{
	width: 70px;
}

.custom-td-width-eb-ver-manage-extra-small{
	width: 50px;
}

.custom-td-width-eb-deploy{
	width: 85px;
	min-width: 85px;
}

.custom-td-buttons-area{
	text-align: center
}

.custom-bastion-buttons-area{
	text-align: justify
}


.custom-td-version-tips-area{
	text-align: center
}

.custom-td-current-version-area[is-current-version="true"]{
	padding: 0.5em 1em;
	margin: 2em 0;
	font-weight: bold;
	border: solid 3px red;/*線*/
	border-radius: 10px;/*角の丸み*/
}

.custom-td-updating-version-area[is-updating-version="true"]{
	padding: 0.5em 1em;
	margin: 2em 0;
	font-weight: bold;
	border: solid 3px yellow;/*線*/
	border-radius: 10px;/*角の丸み*/

	animation-name: flash; /*CSS側で定義したアニメーションの名前*/
	animation-duration: 3s; /*アニメーションの長さ*/
	animation-iteration-count: infinite; /*アニメーションを何回実行するか, infiniteだと無限ループ*/
	animation-direction: alternate; /*alternateにすると、行って帰ってという風にアニメーションを実行する*/
	animation-play-state: running; /*ニメーションを実行中の状態にする*/
}

/*全体の何％進んだところで要素をどうするかの指定。今回は不透明度を0にする*/
@keyframes flash {
	50%{
			opacity: 0;
	}
}

.custom-td-width-alpha2{
	width: 45px;
}
.custom-td-width-alpha4{
	width: 55px;
}
.custom-td-width-alpha6{
	width: 75px;
}
.custom-td-width-alpha7{
	width: 85px;
}
.custom-td-width-alpha8{
	width: 90px;
}

.custom-td-width-top-user{
	width: 110px;
}
.custom-top-card-height{
	/* height: 370px; */
	height: 400px;

}

.custom-card-width{
	width: 90%;
}
.custom-table-scroll{
	/* 要素の幅・高さを指定できるようになる。逆に言えば指定しないと潰れる */
	display: block;
}

.custom-bgcolor-db{
	/* background-color: lightyellow; */
	background-color: ivory;
	width: 100%;
}
.custom-bgcolor-env{
	/* background-color: lightcyan; */
	background-color: azure;
	width: 100%;
}

.custom-bgcolor-odd{
	/* background-color: lightcyan; */
	background-color: ivory;
	width: 100%;
}

.custom-bgcolor-even{
	/* background-color: lightcyan; */
	background-color: azure;
	width: 100%;
}

.custom-td-width-log{
	width: 160px;
	min-width: 160px;
}
.custom-td-width-top-env{
	/* width: 120px; */
	width: 150px;
	/* max-width: 150px; */
}
.custom-td-width-message{
	width: 300px;
	min-width: 300px;
}
.custom-td-width-notice-date{
	width: 160px;
	min-width: 160px;
	/* Maxが効かない */
	max-width: 160px;
}
.custom-td-width-totp{
	width: 160px;
	min-width: 160px;
}
.custom-td-width-totp-verify{
	width: 200px;
	min-width: 200px;
}
.custom-table-height-scroll{
	/* display: block; */
	height: 200px;
	/* width: 100%; */
	overflow-y: auto;
}
.sticky_table thead th {
  /* 縦スクロール時に固定する */
  position: -webkit-sticky;
	position: sticky;
	/* 0だと少しだけ中身がはみ出る */
	top: -1px;
  /* tbody内のセルより手前に表示する */
  z-index: 1;
}
/* .form-inline-center{
	justify-content: center;
} */


/* ======<テーブル用CSS：終了>================================================================== */


/* ======<サイドバー用CSS：開始>================================================================== */

/* メインコンテンツのCSS、マージンはJSで書き換える */

.showSidebarMain > #main{
	/* margin-left: calc(25vw + 10px); */
	margin-left: calc(180px + 10px)
}
.hiddenSidebarMain > #main{
	/* サイドバーを隠す場合 */
	/* margin-left: calc(0vw + 10px); */
	
	/* ★【サイドバー】サイドバーを細くする場合 */
	margin-left: calc(40px + 10px);
}
#main{
	margin: 10px;
}

/* サイドバー用のCSS */
.showMySidebar > #mySidebar{
	/* ナビゲーションバーを無視し、ナビゲーションバーと同じ高さから表示させる（つまり重なる） */
	top: 0px;
	/* width: 25vw; */
	width: 180px;
  display: block;
  /* bootstrap4 navbarの高さが50pxらしい */
	/* height: calc(100vh - 50px); */
	/* ナビゲーションバーと重ねて表示させているため、50pxのマイナスは不要 */
	height: calc(100vh);
  /* 項目名が長い場合、幅によって改行させないと切れる（overflow:scrollでも横方向にスクロール出来ない） */
  word-break: break-all;
}
.hiddenMySidebar > #mySidebar{
	top: 0px;
	/* width: 25vw; */
	width: 40px;

	/* ★【修正点：サイドバー】サイドバーを細くする場合 */
	/* display: none; */
	display: block;
	
	/* bootstrap4 navbarの高さが50pxらしい */
	/* サイドバーをヘッダーの下に埋め込むため、「-50px」は不要 */
	/* height: calc(100vh - 50px); */
	height: calc(100vh);

  /* 項目名が長い場合、幅によって改行させないと切れる（overflow:scrollでも横方向にスクロール出来ない） */
  word-break: break-all;
}

/* ############　以下はブラウザサイズを小さくした時にサイドバーを隠すためのもの。無効化 */

/* bootstrap4のmdは768px以上→767pxで適用するCSSを切り替える（サイドバーを表示している状態でレスポンシブにするためのCSS） */
/* @media screen and (max-width:767px){ */
  /* .showSidebarMain > #main{ */
    /* margin-left: 0vw; */
  /* } */
/* } */
/* @media screen and (max-width:767px){ */
  /* .showMySidebar > #mySidebar{ */
    /* margin-left: 0vw; */
    /* display: none; */
  /* } */
/* } */
/* @media screen and (max-width:767px){ */
  /* .showSidebar > .overlay{ */
    /* top leftの分だけ横幅と高さを調整 */
    /* なぜかleft: -0vwを指定しないと、左マージンが入った状態で表示される。なぞ */
    /* left: -0vw; */
    /* width: calc(100vw); */
    /* height: calc(100%); */
    /* position: fixed; */
    /* background: rgba(0, 0, 0, 0.5); */
    /* z-index: 1; */
    /* display: flex; */
    /* align-items: center; */
    /* justify-content: center; */
  /* } */
/* } */

/* ======<サイドバー用CSS：終了>================================================================== */


/* 全てを囲んでいるDiv（.test-heightと同等）。ここの高さを減らすと色だけ足りなくなる→中身の問題（S3だとスクロールが発生しない） */
.content{
  min-height: 100vh;
	height: 100%;
	/* min-width: 1000px;
	overflow: scroll; */
}
body{

}
/* 区切り線の色付き */
.overline-blue{
	border-top: 1px solid blue;
}
.overline-orange{
	border-top: 1px solid orange;
}
.underline-blue{
	border-bottom: 1px solid blue;
}

/* モーダルを表示すると画面右端に白い空白が出来る問題への対処 */
.modal-open {
  padding-right: 0px !important;
  overflow: auto;
}
.modal {
  padding-right: 0px !important;
}

/* .navbar {
  padding-right: 0 !important;
} */
.image-resize{
	height: 30px;
	/* 縦横比を変えずに大きさを変更するため、Autoを設定 */
	width: auto;
	/* 画像が文字と比べて沈んでいる気がするので、高さを合わせる */
	margin-top: -7px;
}
.image-rotate{
	/* transform: rotate(90deg); */
	height: 50px;
	width: auto;
}
.fa-custom-rotate{
	transform: rotate(15deg);
}
/* .fa-custom-position{
	margin-bottom: 0px;
} */

.height-for-navbar{
	height: 50px;
}

.general-card{
	/* 全てのカードの左右と上の幅 */
	width: 99%;
	margin-top: 0.5%;
}

.bg-color-green{
	background-color: rgb(207, 253, 158);
}
.bg-color-yellow{
	background-color: rgb(250, 253, 146);
}
.bg-color-red{
	background-color: rgb(255, 193, 193);
}
.bg-color-grey{
	background-color: rgb(233, 233, 233);
}

.custom-tooltip{
	/* z-index: 999999 !important; */
	opacity: 1 !important;
}
.min-height-display{
	min-height: calc(100vh - 52px);
}


/* 細いサイドバーを表示するためのCSS */

.custom-margin-left{
	/* サイドバーが細くなっている時のハンバーガーアイコンの左マージン */
	margin-left: 2px;
}

/* Footer */
.footer-height{
	height: 30px;
}


/* IE11のみに適用するCSS */
@media all and (-ms-high-contrast: none){
	.sticky-top{
		position:fixed;
     width:100%;
     z-index:1000;
     top:0px;
	}
	.showSidebar > .overlay{
		/* top leftの分だけ横幅と高さを調整。書かないと#mainのマージンに影響される */
		top: 0px;
		/* left: 25vw; */
		left: 180px;
		width: calc(100% - 180px);
		/* height: 100% - top は不要 */
		height: calc(100%);
		position: fixed;
		background: rgba(0, 0, 0, 0.5);
		z-index: 999;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	
	
	.hiddenSidebar > .overlay{
		/* top leftの分だけ横幅と高さを調整。書かないと#mainのマージンに影響される */
		top: 0px;
		/* ★【サイドバー】サイドバーを細くする場合 */
		left: 40px;
		width: calc(100% - 40px);
		/* height: 100% - top は不要 */
		height: calc(100%);
		position: fixed;
		background: rgba(0, 0, 0, 0.5);
		z-index: 999;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	
	/* ローディングスピナーの色を指定 */
	.my-white{
		color: white;
	}
	
	/* サインイン時は、ローディングに左マージンを入れない */
	.hiddenSidebarSignIn > .overlay{
		/* top leftの分だけ横幅と高さを調整。書かないと#mainのマージンに影響される */
		top: 0px;
		/* サイドバーを隠す場合 */
		left: 0vw;
		width: calc(100%);
		/* height: 100% - top は不要 */
		height: calc(100%);
		position: fixed;
		background: rgba(0, 0, 0, 0.5);
		z-index: 999;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}